import "./Monetization.scss";


const Monetization = () => {
    return (
        <div className="monetization">
            <div className="title">Join our Innovative Affiliate Program!</div>
            <div className="elaboration">Open Up New Horizons of Income with Our Unique Solution for Watching Comics and Manga!</div>
            <div className="details">
                Do you run a comic book or manga platform and are looking for ways to increase revenue without losing
                audience loyalty? Our product offers an innovative solution that combines the convenience of viewing
                with monetization capabilities. We present you with a unique model that will allow you to maximize
                revenue from your content while maintaining a high quality user experience.
            </div>
        </div>
    );
};

export default Monetization;
