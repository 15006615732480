import SwipeableViews from "react-swipeable-views-react-18-fix";
import AppBar from "@mui/material/AppBar";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import Box from "@mui/material/Box";
import { useCallback, useState } from "react";
import "./Main.scss";
import { Toolbar } from "@mui/material";
import GLOBAL from "../Consts";
import useRenderTab from "./tabs/useRenderTab";
import Logo from "./tabs/elements/Logo";

function a11yProps(index) {
    return {
        id: `full-width-tab-${index}`,
        "aria-controls": `full-width-tabpanel-${index}`,
    };
}

var scrollLocked = false;

export default function Main() {
    const [value, setValue] = useState(0);

    const renderTab = useRenderTab();
    const handleChange = (event, newValue) => {
        setValue(newValue);
    };

    const handleChangeIndex = (index) => {
        setValue(index);
    };

    const tabs = Object.entries(GLOBAL.tabs).map((el) => el[1]);

    const onWheel = useCallback((e) => {
        if (window.innerHeight >= 500 && window.innerWidth >= 1000) {
            if (scrollLocked) return;
            if (e.deltaY > 0) { // down
                setValue(Math.min(value + 1, tabs.length - 1));
                scrollLocked = true;
                setTimeout(() => {scrollLocked = false;}, 500);
            } else {
                setValue(Math.max(value - 1, 0));
                scrollLocked = true;
                setTimeout(() => {scrollLocked = false;}, 500);
            }
        }
    }, [value, setValue, tabs]);

    return (
        <Box className="app" onWheel={onWheel}>
            <AppBar position="fixed" className="topbar">
                <Toolbar disableGutters className="toolbar">
                    <Logo />
                    <Tabs
                        value={value}
                        onChange={handleChange}
                        textColor="inherit"
                        className="navigation"
                        variant="scrollable"
                    >
                        {tabs.map((el, idx) => (
                            <Tab key={el} label={el} {...a11yProps(idx)} />
                        ))}
                    </Tabs>
                    <div style={{ opacity: 0 }} className="logo-crutch">
                        <Logo />
                    </div>
                </Toolbar>
            </AppBar>
            <SwipeableViews axis="y" index={value} onChangeIndex={handleChangeIndex} style={{ height: "100vh" }}>
                {tabs.map((el, idx) => renderTab(el, idx, value))}
            </SwipeableViews>
        </Box>
    );
}
