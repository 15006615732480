import "./Mission.scss";


const Mission = () => {
    return (
        <div className="mission">
            <div className="elaboration">Our Mission</div>
            <p>
                At Talios Technologies, we are committed to revolutionizing the world of manga and comic book reading.
                Our mission is to provide a cutting-edge automatic reading service that transforms the way electronic
                comics are perceived and enjoyed.
            </p>

            <div className="elaboration">Our Values</div>

            <ul>
                <li>
                    <b>Improving the World</b>: We believe in the power of storytelling and its ability to unite people. Our
                    work is dedicated to creating a more equitable and connected world through shared stories and
                    experiences.
                </li>

                <li>
                    <b>Humanity</b>: We strive to develop technology that makes cultural content more accessible to people of
                    all backgrounds, ages, origins, or physical abilities.
                </li>

                <li>
                    <b>Technological Advancement</b>: In the digital age, we are focused on developing cutting-edge solutions
                    that not only meet current trends but also set new standards in the industry.
                </li>
            </ul>

            <div className="elaboration">Our Vision</div>

            <p>
                Our ambition is for every manga and comic reader to experience a new level of story immersion and visual
                engagement. We aim to establish a new standard in the consumption of electronic comics, where each page
                comes to life through our technology.
            </p>

            <p>
                At Talios Technologies, we don't just keep up with changes – we create them. Join us on this journey to
                expand the boundaries of what's possible in the world of comics and manga.
            </p>
        </div>
    );
};

export default Mission;
