import "./Pricing.scss";
import CheckCircleIcon from '@mui/icons-material/CheckCircle';


const PriceOption = ({name, price, description, properties, ...props}) => (
    <div className="card">
        <div className="name">{name}</div>
        <div className="description">{description}</div>
        <div className="price">{price > 0 ? `$${price}` : (price === 0 ? "$Free + we pay" : "$Negotiable")}</div>
        <div className="separator"></div>
        <ul className="properties">{
            properties.map(property => (
                <li key={property.replaceAll(" ", "_")}><CheckCircleIcon/>{property}</li>
            ))
        }</ul>
    </div>
);

const Pricing = () => {
    const cards = [{
        type: "free",
        name: "🌟 Advertising Plan",
        description: "Increase revenue without sacrificing user experience",
        price: 0,
        properties: [
            "Revenue model: We share the revenue from the ads shown during the use of our product on your site.",
            "Flexible profit sharing: A contractual share of the advertising revenue generated by your content.",
            "Easy to integrate: Easily integrates into your current website.",
            "No initial investment: Start without paying in advance.",
            "Support and Analytics: Full technical support and access to usage analytics.",
        ]
    }, {
        type: "pro",
        name: "💎 Premium Plan",
        description: "Exclusivity and freedom from advertising for your users",
        price: -1,
        properties: [
            "Payment model: You pay a fixed fee for using our tool.",
            "Full control over the user experience: Offer your users continuous and comfortable viewing without ads.",
            "Personalization: The ability to customize the appearance and functions according to your brand.",
            "Support and Analytics: Full technical support and access to usage analytics.",
            "Premium Support: Priority technical support and advice.",
        ]
    }];
    return (
        <div className="pricing">
            <div className="content-wrap">
                {cards.map(card => <PriceOption key={card.type} {...card} />)}
            </div>
        </div>
    )
}

export default Pricing;