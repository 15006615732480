const GLOBAL = {
    tabs: {
        product: "Product",
        howItWorks: "How it works",
        mission: "Mission",
        contacts: "Contacts",
        company: "Company",
    },
};

export default GLOBAL;
