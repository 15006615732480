import "./App.css";
import Main from "./page/Main";

function App() {
    return (
        <Main />
    );
}

export default App;
