import { Box } from "@mui/material";
import Product from "./Product";
import GLOBAL from "../../Consts";
import Pricing from "./Pricing";
import HowItWorks from "./HowItWorks";
import Mission from "./Mission";
import Company from "./Company";
import HowToIntegrate from "./HowToIntegrate";
import Monetization from "./Monetization";
import Contacts from "./Contacts";

function TabPanel(props) {
    const { children, value, index, ...other } = props;

    return (
        <div
            role="tabpanel"
            id={`full-width-tabpanel-${index}`}
            aria-labelledby={`full-width-tab-${index}`}
            style={{ height: "100vh" }}
            {...other}
        >
            <Box sx={{ p: 3 }} className="page-wrapper">
                {children}
            </Box>
        </div>
    );
};

const useRenderTab = () => (el, idx, value) => {
    const selectContent = (tabName) => {
        switch (tabName) {
            case GLOBAL.tabs.product:
                return <Product />;
            case GLOBAL.tabs.pricing:
                return <Pricing />;
            case GLOBAL.tabs.howItWorks:
                return <HowItWorks />
            case GLOBAL.tabs.mission:
                return <Mission />
            case GLOBAL.tabs.company:
                return <Company />
            case GLOBAL.tabs.howToIntegrate:
                return <HowToIntegrate />
            case GLOBAL.tabs.monetization:
                return <Monetization />
            case GLOBAL.tabs.contacts:
                return <Contacts />
            default:
                return <div>To do</div>;
        }
    }

    return (
        <TabPanel key={el} value={value} index={idx} className="tabpanel">
            {selectContent(el, idx, value)}
        </TabPanel>
    );
}

export default useRenderTab;