import { Button } from "@mui/material";
import "./Contacts.scss";

const Contacts = () => {
    return (
        <div className="contacts">
            <div className="title">How to contact us</div>
            <p style={{textAlign: "center"}}>
                If you like our idea and want to follow the development news, you can subscribe to our Patreon and X (ex. Twitter) and join our Discord channel.
            </p>
            <div className="link-images-container">
                <a href="https://www.patreon.com/user/posts?u=118086921">
                    <img src="/patreon.png" alt="patreon"></img>
                </a>
                <a href="https://twitter.com/ComicAutoReader">
                    <img src="/twitter.png" alt="twitter"></img>
                </a>
                <a href="https://discord.gg/e2ZkeU6J">
                    <img src="/discord.png" alt="patreon"></img>
                </a>
            </div>
            <p>
                Also you can subscribe to our email newsletter.
            </p>
           <Button variant="contained" className="ml-onclick-form" onClick={() => window.ml('show', 'yQJCWK', true)}>Subscribe</Button>
        </div>
    );
};

export default Contacts;
