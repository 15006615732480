import "./Logo.scss";


const Logo = () => (
    <div className="logo">
        <img src="/CARlogo.png" alt="logo"></img>
        <span>ComicAutoReader</span>
    </div>
)

export default Logo;
