import "./HowToIntegrate.scss";

const HowToIntegrate = () => (
    <div className="how-to-integrate">
        <p style={{textAlign: "center"}}>
            We have simplified the integration process as much as possible. To use our product, all you have to do is
            simply connect our player to your website using an iframe.
        </p>
        <video className="example integration" autoPlay loop muted>
            <source src="/integration.mp4" type="video/mp4" />
        </video>
    </div>
);

export default HowToIntegrate;
