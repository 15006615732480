import "./Company.scss";

const Company = () => {
    return (
        <div className="company">
            <div className="title">Who we are</div>
            <div className="company-description">
                <div>
                    <p>
                        Talios Technologies is the company of like-minded people who is going to change the world perception
                        of entertainment.
                    </p>
                    <p>If you are interested in cooperation, please email us: <b>comic.auto.reader@gmail.com</b></p>
                </div>
                <img src="/TaliosLogo.png" alt="CompanyLogo"></img>
            </div>
            <div className="tail">
                ©Talios Technologies. All rights reserved.
            </div>
        </div>
    );
};

export default Company;
