import "./Product.scss";

const mangaList = [
    {
        src: "/ranobe.jpg",
        alt: "ranobe",
        text: "Ranobe",
    },
    {
        src: "/manga.png",
        alt: "manga",
        text: "Manga",
    },
    {
        src: "/questions.png",
        alt: "questions",
        text: "???",
    },
    {
        src: "/anime.jpg",
        alt: "anime",
        text: "Anime",
    },
];

const comicList = [
    {
        src: "/comics.png",
        alt: "comics",
        text: "Comics",
    },
    {
        src: "/questions.png",
        alt: "questions",
        text: "???",
    },
    {
        src: "/movie.jpg",
        alt: "movie",
        text: "Cartoon / Movie",
    },
];

const Product = () => {
    return (
        <div className="product">
            <div className="title">A new way to read manga and comics</div>
            <div className="evolution">
                <div className="header">We've found a missed link!</div>
                {[mangaList, comicList].map(
                    (list, idx) => (
                        <div className={`chain-block block-${idx}`} key={idx}>
                            {list.map((el) => (
                                <div key={el.alt}>
                                    <img src={el.src} alt={el.alt} />
                                    <div>{el.text}</div>
                                </div>
                            ))}
                        </div>
                    )
                )}
            </div>
        </div>
    );
};

export default Product;
