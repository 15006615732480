import DoubleArrowRoundedIcon from "@mui/icons-material/DoubleArrowRounded";
import "./HowItWorks.scss";

const HowItWorks = () => {
    return (
        <div className="how-it-works">
            <div className="title">Just one click</div>
            <p style={{ textAlign: "center" }}>
                You install our browser extension, then go to your favorite manga site. All you have to do is activate
                the extension, and we will prepare materials that you can easily access through our web player.
            </p>
            <div className="pipeline">
                <img src="/comicsIn.png" alt="comicsIn"></img>
                <DoubleArrowRoundedIcon className="transition" />
                <video className="example reader" controls loop>
                    <source src="/mangaVideo.mp4" type="video/mp4" />
                </video>
            </div>
        </div>
    );
};

export default HowItWorks;
